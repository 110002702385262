
 /* author: Jhey
https://codepen.io/jh3y/pen/MWbvzKb */
 
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&family=Roboto:wght@100;300&display=swap");
:root {
    --button: #b3b3b3;
    --button-color: #0a0a0a;
    --shadow: #000;
    --bg: #737373;
    --header: #7a7a7a;
    --color: #fafafa;
    --lit-header: #e6e6e6;
    --speed: 2s;
  }

  
