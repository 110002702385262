@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
 
  margin: 0 auto;
  scroll-behavior: smooth;
  font-family: sans-serif;
}
.outlet {
  max-width: 1600px;
  margin: 0 auto;
}
/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}

.hero {
  background-image: url('./assets/fenve.jpg');
  background-position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 65vh;
 
}
.heads  {
  -webkit-text-stroke: 1px black;
}
.headsa {
  -webkit-text-stroke: .1px black;
}
.chain {
  background-image: url('./assets/cahinz1.jpg');
  background-position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 65vh;
 
}
.barbed {
  background-image: url('./assets/barbedwire.jpg');
  background-position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 65vh;
}

.perimeter {
  background-image: url('./assets/fenve.jpg');
  background-position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 65vh;
}
.gate {
  background-image: url('./assets/construct/gate2.jpg');
  background-position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 65vh;
}
.concrete {
  background-image: url('./assets/concrete/concrete4.jpeg');
  background-position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 45vh;
}
.wooden {
  background-image: url('./assets/pixelcut-export.png');
  background-position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 65vh;
}

.ul1 {
  display: flex;
}

.ul1 li {
  list-style: none;
}

.ul1 li a {
  width: 30px;
  height: 30px;

  text-align: center;
  line-height: 30px;
  font-size: 22px;
  margin: 0 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 1px solid #fff;
  z-index: 1;
}

.ul1 li a .icon {
  position: relative;
color: #fff;
  transition: .5s;
  z-index: 3;
}

.ul1 li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

.ul1 li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: .5s;
  z-index: 2;
}

.ul1 li a:hover:before {
  top: 0;
}
.ul1 li:nth-child(1) a:before{
  background: #f00;
}

.ul1 li:nth-child(2) a:before{
  background: #3b5999;
}

.ul1 li:nth-child(3) a:before {
  background: #0077b5;
}
.ul1 li:nth-child(4) a:before {
  background: #000000;
}
.ul1 li:nth-child(5) a:before {
  background: #dd4b39;
}
.ul1 li:nth-child(6) a:before {
  background: #000000;
}



@media (max-width: 768px) {
  .hero {
    height: 50vh;
  }
  .heroz {
    height: auto;
  }
  }




.glide__arrow--left {
  left: 10px;
}

.glide__arrow--right {
  right: 10px;
}

.glide__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 1;
}

.glide__arrow--left {
  left: 10px; /* Adjust as needed */
}

.glide__arrow--right {
  right: 10px; /* Adjust as needed */
}

.glide__arrow div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem; /* 9 * 0.25rem */
  height: 2.25rem;
  background-color: #920a0a; /* bg-gray-800 */
  border-radius: 0.75rem; /* rounded-xl */
  transition: background-color 0.3s ease-in-out;
}

.glide__arrow div:hover {
  background-color: #ff1414; /* hover:bg-red-200 */
}

.glide__arrow i {
  color: #1fcdd3; /* text-red-200 */
  font-size: 1.5rem; /* text-2xl */
}

